import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Chip,
  IconButton,
  Typography,
  Grid,
  ButtonGroup,
  Tooltip,
  Divider,
  Collapse,
  Paper,
  Link,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { useParams } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import moment from "moment";
import ToastContext from "../context/ToastContext";
import AssetIcon from "../components/AssetIcon";
import PageNotFoundInner from "../404Inner";
import ResilienceIcon from "../icons/resilienceIcon";
import { useApi } from "../API";
import AssetCVETable from "../risk/AssetCVETable";
import AssetAlertTable from "../risk/AssetAlertTable";
import spinner from "../assets/res-spinner.svg";
import DataDiscoveryResult from "./DataDiscoveryResult";
import UserLogons from "./UserLogons";
import { palette } from "../utils/theme";
import useSettings from "../hooks/useSettings";
import ProbabilityDistributionCurve from "../risk/ProbabilityDistributionCurve";
import AccordionSection from "../components/AccordionSection";
import StyledModal from "../components/StyledModal";
import getLogoUrl from "../settings/connectors/ConnectorUtils";

const FactorsTree = ({
  riskData,
  impactData,
  isCritical,
  impactScore,
  riskScore,
}) => {
  if (
    !riskData?.likelihood?.length &&
    !riskData?.impact?.length &&
    !impactData.length
  ) {
    return <div style={{ margin: "1em" }}>No risk factor data available</div>;
  }

  return (
    <Grid container style={{ paddingLeft: "15em", paddingRight: "15em" }}>
      <Grid
        container
        justifyContent="space-around"
        style={{
          zIndex: 100,
        }}
      >
        <Grid
          item
          alignItems="center"
          container
          xs={4}
          style={{
            backgroundColor: palette.background,
            padding: "1.5em 2.5em",
          }}
          justifyContent="space-between"
        >
          <Typography variant="h6">Asset Risk</Typography>
          <AssetIcon risk riskPercentage={riskScore} svgWidth={55} />
        </Grid>
      </Grid>
      <Grid container style={{ zIndex: 1 }}>
        <Grid
          item
          style={{ height: "1px", zIndex: -100 }}
          justifyContent="space-around"
          alignItems="center"
          container
        >
          <div
            style={{
              height: "150px",
              width: "150px",
              borderLeft: "3px solid #5E5E5E",
              borderTop: "3px solid #5E5E5E",
              transform: "rotate(45deg)",
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={5} style={{ marginTop: ".3em" }}>
        <Grid alignItems="flex-start" item container xs={6}>
          <Grid
            container
            item
            style={{
              backgroundColor: palette.background,
              padding: "1em",
              zIndex: 100,
            }}
            direction="column"
          >
            <Grid
              item
              container
              justifyContent="space-between"
              style={{ padding: ".5em" }}
            >
              <Grid item>
                <Typography variant="h6">Impact</Typography>
              </Grid>
              <Grid item>
                <AssetIcon
                  criticalityPercentage={impactScore}
                  isCritical={isCritical}
                  svgWidth={45}
                />
              </Grid>
            </Grid>
            {/* {data?.impact?.map((el, idx) => {
              const barWidth = Math.abs(parseFloat(el.shap_value).toFixed(0));
              return (
                <Grid
                  item
                  xs={12}
                  key={idx}
                  alignItems="center"
                  alignContent="center"
                  container
                  justifyContent="space-between"
                  style={{
                    fontWeight: 500,
                    backgroundColor: "white",
                    padding: "1em",
                    margin: ".2em 0",
                    height: "50px",
                  }}
                >
                  <Grid item xs={3}>
                    {el.display_name}
                  </Grid>
                  <Grid item xs={9} alignItems="center" alignContent="center">
                    <div
                      style={{
                        height: ".75em",
                        margin: "1em",
                        backgroundColor: isCritical
                          ? palette.highImpact
                          : palette.lowImpact,
                        width: `${barWidth}%`,
                      }}
                    />
                  </Grid>
                </Grid>
              );
            })} */}
            {impactData?.map((el, idx) => {
              const barWidth = Math.abs(parseFloat(el.shap_value).toFixed(0));
              return (
                <Grid
                  item
                  xs={12}
                  key={idx}
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  alignContent="center"
                  style={{
                    fontWeight: 500,
                    backgroundColor: "white",
                    padding: "1em",
                    margin: ".2em 0",
                    height: "50px",
                  }}
                >
                  <Grid item xs={3}>
                    {el.display_name}
                  </Grid>
                  <Grid item xs={9} alignItems="center" alignContent="center">
                    <div
                      style={{
                        height: ".75em",
                        margin: "1em",
                        backgroundColor: isCritical
                          ? palette.highImpact
                          : palette.lowImpact,
                        width: `${barWidth}%`,
                      }}
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid alignItems="flex-start" item container xs={6}>
          <Grid
            container
            item
            style={{
              backgroundColor: palette.background,
              padding: "1em",
              zIndex: 100,
            }}
            direction="column"
          >
            <Grid item style={{ padding: ".5em" }}>
              <Typography variant="h6">Likelihood</Typography>
            </Grid>
            {riskData?.likelihood?.map((el, idx) => {
              const barWidth = Math.abs(parseFloat(el.shap_value).toFixed(0));
              let riskFill;
              if (riskScore < 40) {
                riskFill = palette.green;
              } else if (riskScore < 80 && riskScore >= 40) {
                riskFill = palette.yellow;
              } else if (riskScore >= 80 && riskScore <= 100) {
                riskFill = palette.red;
              }
              return (
                <Grid
                  item
                  xs={12}
                  key={idx}
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  alignContent="center"
                  style={{
                    fontWeight: 500,
                    backgroundColor: "white",
                    padding: "1em",
                    margin: ".2em 0",
                    height: "50px",
                  }}
                >
                  <Grid item xs={3}>
                    {el.display_name}
                  </Grid>
                  <Grid item xs={9} alignItems="center" alignContent="center">
                    <div
                      style={{
                        height: ".75em",
                        margin: "1em",
                        backgroundColor: riskFill,
                        width: `${barWidth}%`,
                      }}
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: "white",
    padding: "2em",
  },
  root: {
    flexGrow: 1,
    margin: "-32px 1rem 1rem",
    maxWidth: "100vw",
    backgroundColor: "#E9EBEE",
    paddingTop: "2em",
    paddingBottom: "2em",
  },
  table: {
    fontSize: "110%",
  },
  section: {
    flexGrow: 1,
    margin: "1rem",
    maxWidth: "100vw",
  },
  scans: {
    margin: "2rem 1rem 3rem 1rem",
    paddingBottom: "2rem",
  },
  item: {
    marginBottom: "0",
    maxWidth: "100vw",
    flexGrow: 1,
    minWidth: 0,
  },
  keyName: {
    width: "200px",
  },
  assetBanner: {
    position: "relative",
    padding: "1em",
    // paddingRight: ".5em",
    // paddingTop: ".5rem",
    display: "flex",
    backgroundColor: "white",
    marginBottom: "2em",
  },
  assetIcon: {
    display: "block",
    alignItems: "center",
    borderRadius: "4px 4px 0 0",
    padding: "0 16px",
  },
  assetBannerData: {
    backgroundColor: "white",
    display: "flex",
    justifyContent: "flexStart",
    alignItems: "center",
    flexWrap: "wrap",
    position: "relative",
  },
  assetData: {
    lineHeight: "24px",
    height: "24px",
    whiteSpace: "nowrap",
  },
  assetDataWrapper: {
    display: "flex",
    flexGrow: 1,
    flexWrap: "wrap",
    margin: "0 -48px",
    padding: "0 48px",
    alignItems: "center",
    paddingLeft: "5em",
  },
  assetDataLabel: {
    fontSize: ".775rem",
    color: "#383D3A",
  },
  // modalButtons: {
  //   position: "relative",
  //   right: "-16px",
  //   top: "-56px",
  //   height: "48px",
  //   display: "flex",
  //   justifyContent: "flex-end",
  // },
  assetEditButton: {
    position: "relative",
    top: "48px",
    right: "8px",
  },
  viewInResilienceButton: {
    position: "relative",
    top: "48px",
    fontSize: "14px",
    marginRight: "8px",
  },
  viewInRiskButton: {
    position: "relative",
    top: "48px",
    fontSize: "14px",
    marginRight: "4px",
  },
  coverageImg: {
    height: "1.25rem",
    marginTop: ".5rem",
    marginRight: "1rem",
  },
  softwarePackage: {
    marginTop: ".5rem",
    marginRight: "1rem",
  },
}));

function Drilldown(props) {
  const classes = useStyles();
  const [settings] = useSettings("financial_risk_enabled");
  const api = useApi();
  const { assetId = props.assetId } = useParams();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [assetData, setData] = useState(null);
  const [assetData2, setData2] = useState(null);
  const [riskData, setRiskData] = useState(null);
  const [impactData, setImpactData] = useState(null);
  const [activeSolutions, setActiveSolutions] = useState([]);
  const [controls, setControls] = useState([]);
  const [description, setDescription] = useState("");
  const [softwarePackages, setSoftwarePackages] = useState([]);
  const [ports, setPorts] = useState([]);
  const [userLogons, setUserLogons] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [pageError, setPageError] = useState(false);
  const [allExpanded, setAllExpanded] = useState(true);
  const [showAllSoftware, setShowAllSoftware] = useState(false);
  const [showAllPorts, setShowAllPorts] = useState(false);
  const [showAllFirewall, setShowAllFirewall] = useState(false);
  const toastContext = useContext(ToastContext);

  const printControls = (controls) => {
    const displayControls = [];

    controls.forEach((control) => {
      if (control.status === 1) {
        displayControls.push([control.logo_url, control.display_name, control.solution_id]);
      }
    });

    return displayControls;
  };

  const lookupFieldValue = (fieldName) => {
    if (
      assetData &&
      assetData.overrides &&
      assetData.overrides[fieldName] !== null &&
      assetData.overrides[fieldName] !== undefined
    ) {
      return assetData.overrides[fieldName];
    }
    return assetData[fieldName.toLowerCase()];
  };

  const primaryFields = [
    {
      name: "name",
      label: "Alias",
    },
    {
      name: "hostname",
      label: "Hostname",
    },
    {
      name: "ipv4",
      label: "Last IP Address",
    },
    // {
    //     name: "external_ip",
    //     label: "Last External IP",
    // },
    // {
    //     name: "cvss_score",
    //     label: "Max CVSS Score",
    // },
    {
      name: "asset_type",
      label: "Asset Type",
    },
    {
      name: "location",
      label: "Location",
    },
    {
      name: "environment",
      label: "Environment",
    },
    // {
    //     name: "business_unit",
    //     label: "Business Unit",
    // },
    // {
    //     name: "application",
    //     label: "Application",
    // },
    // {
    //     name: "owner",
    //     label: "Owner",
    // },
    // {
    //     name: "asset_uuid",
    //     label: "Vendor Asset ID",
    // },
  ];
  useEffect(async () => {
    try {
      const assetImpactDetails = await api.impactAsset(assetId);
      assetImpactDetails.kpis.map((obj) => {
        obj['display_name'] = obj['display_name'].split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      })
      setImpactData(assetImpactDetails);
    } catch (e) {
      console.log("error", e);
      // setPageError(e)
    } finally {
      // setLoaded(true)
    }
  }, []);

  useEffect(async () => {
    try {
      const solutions = await api.getActiveSolutions();
      setActiveSolutions(solutions);
    } catch (e) {
      console.log("error", e);
      // setPageError(e)
    } finally {
      // setLoaded(true)
    }
  }, []);

  const loadAssetData = (assetId) => {
    if (assetId) {
      api
        .crownJewelsDetails(assetId)
        .then((response) => {
          setData(response);
          // This implementation needs to be refactored.
          setData2([
            {
              value:
                response.overrides && response.overrides.business_unit
                  ? response.overrides.business_unit
                  : response.business_unit,
              key: "Business Unit",
              id: "business_unit",
            },
            {
              value: response.external_ip,
              key: "Last External IP",
              id: "external_ip",
            },
            {
              value: response.application,
              key: "Business Process",
              id: "application",
            },
            {
              value:
                response.overrides && response.overrides.owner
                  ? response.overrides.owner
                  : response.owner,
              key: "Owner",
              id: "owner",
            },
            {
              value: response.asset_uuid,
              key: "Vendor Asset ID",
              id: "asset_uuid",
            },
            {
              key: "Internet Facing",
              id: "internet_facing",
              value: response.internet_facing ? "Yes" : "No",
            },
            {
              id: "agent_version",
              key: "EDR Agent Version",
              value: response.agent_version,
            },
            {
              id: "health_status",
              key: "EDR Agent Health",
              value: response.health_status,
            },
            {
              id: "onboarding_status",
              key: "EDR Onboarding Status",
              value: response.onboarding_status,
            },
            // {
            //   id: "ports_services",
            //   key: "Ports",
            //   value: response.ports_services.join(", "),
            // },
            { id: "date_added", key: "Date Added", value: response.date_added },
            {
              id: "date_last_seen",
              key: "Date Last Seen",
              value: response.date_last_seen,
            },
            { id: "class", key: "Class", value: response.class },
            { id: "os", key: "Operating System", value: response.os },
            { id: "os_version", key: "OS Version", value: response.os_version },
            {
              id: "manufacturer",
              key: "Manufacturer",
              value: response.manufacturer,
            },
            { id: "model_id", key: "Model ID", value: response.model_id },
            {
              id: "one_hop_connections",
              key: "Total One Hop Connections",
              value: response.one_hop_connections,
            },
          ]);
          setDescription(
            response.overrides && response.overrides.description
              ? response.overrides.description
              : response.description
          );
          setControls(response.controls);
          setSoftwarePackages(response.installed_software);
          setPorts(response.ports_services);
          setUserLogons(response.user_logons);
        })
        .then(() => setLoaded(true))
        .catch((error) => {
          setLoaded(true);
          console.log(error);
          setPageError(true);
        });
    }
  };

  const toggleExpandAll = () => {
    setAllExpanded(!allExpanded);
  };

  const deleteAsset = async (assetId) => {
    try {
      await api.deleteAsset(assetId);
      setDeleteModalOpen(false);
      toastContext.showToast("open", "success", "Succesfully deleted asset");
      window.location.href = "/app/inventory/assets";
    } catch (e) {
      console.log(e);
      setDeleteModalOpen(false);
      toastContext.showToast("open", "error", "Error deleting asset");
    }
  };

  const launchDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  useEffect(async () => {
    try {
      const assetRiskDetails = await api.riskV2Asset(assetId);
      assetRiskDetails.kpis.likelihood.map((obj) => {
        obj['display_name'] = obj['display_name'].split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      })
      setRiskData(assetRiskDetails);
    } catch (e) {
      console.log("error", e);
      // setPageError(e)
    } finally {
      // setLoaded(true)
    }
  }, []);

  useEffect(() => {
    loadAssetData(assetId);
  }, [assetId]);

  // document.title = `${t("critScoreLabel")} - Asset Details`;
  return (
    <>
      {!loaded && (
        <div
          style={{
            paddingLeft: "2rem",
            paddingTop: ".5rem",
            paddingBottom: "10rem",
          }}
        >
          <img src={spinner} alt="Loading" />
        </div>
      )}
      {loaded && pageError && (
        <div style={{ marginTop: "-1rem" }}>
          <PageNotFoundInner
            errorStatus="Asset Not Found"
            errorMsg="Please Try Again"
          />
        </div>
      )}
      {loaded && !pageError && (
        <div
          style={{ backgroundColor: "#E9EBEE" }}
          data-item="inventory-page-asset-detail"
        >
          <Paper variant="outlined" className={classes.assetBanner}>
            {primaryFields.map((field, index) => (
              <Grid
                container
                key={index}
                justifyContent="space-around"
                direction="column"
                alignContent="center"
              >
                <Grid item>
                  <Typography
                    variant="h6"
                    style={{ fontSize: "medium" }}
                    data-test={`details-${field.name}`}
                  >
                    {field.name === "criticality"
                      ? Math.floor(
                        Number.parseFloat(lookupFieldValue(field.name))
                      ).toFixed(0)
                      : lookupFieldValue(field.name) || "--"}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body2"
                    style={{ color: palette.darkGray }}
                  >
                    {field.label}
                  </Typography>
                </Grid>
              </Grid>
            ))}
            <Grid
              container
              direction="column"
              justifyContent="space-around"
              alignItems="center"
            >
              <Grid item style={{ textAlign: "center" }}>
                <AssetIcon
                  criticalityPercentage={Math.floor(
                    Number.parseFloat(lookupFieldValue("criticality"))
                  ).toFixed(0)}
                  isCritical={lookupFieldValue("is_critical")}
                  svgWidth={45}
                />
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  style={{ textAlign: "center", color: palette.darkGray }}
                >
                  Impact Score
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="column" justifyContent="flex-end">
              <Grid item style={{ textAlign: "center" }}>
                {riskData?.risk_score !== null &&
                  riskData?.risk_score !== undefined ? (
                  <AssetIcon
                    svgWidth={45}
                    risk
                    riskPercentage={riskData?.risk_score}
                  />
                ) : (
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: "gray",
                    }}
                  >
                    No Risk Score
                  </Typography>
                )}
                <Grid item>
                  <Typography
                    variant="body2"
                    style={{ textAlign: "center", color: palette.darkGray }}
                  >
                    Risk Score
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Box style={{ marginLeft: "auto" }}>
              <ButtonGroup color="secondary" variant="outlined">
                <Tooltip title="Resilience Graph">
                  <IconButton
                    // className={classes.viewInResilienceButton}
                    color="secondary"
                    href={`/app/resilience/connections/asset/${assetId}`}
                    title="View Connections"
                  >
                    <ResilienceIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete Asset">
                  <IconButton
                    // className={classes.assetEditButton}
                    title="Delete this Asset"
                    onClick={launchDeleteModal}
                    data-test="delete-asset-btn"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit Asset">
                  <IconButton
                    // className={classes.assetEditButton}
                    title="Edit this Asset"
                    href={`/app/inventory/edit/${assetId}`}
                    data-test="edit-asset-button"
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={allExpanded ? "Collapse All" : "Expand All"}>
                  <IconButton
                    // className={classes.assetEditButton}
                    title="Expand/Collapse All"
                    onClick={toggleExpandAll}
                    color="secondary"
                    variant="outlined"
                  >
                    {allExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </Tooltip>
              </ButtonGroup>
            </Box>

            {/* </div> */}
          </Paper>
          <div className={classes.root}>
            <AccordionSection
              title={
                <Typography variant="h6" style={{ fontSize: "medium" }}>
                  Details
                </Typography>
              }
              titleVariant="subtitle2"
              expanded={allExpanded ?? props.detailsOpen ?? false}
            >
              <Grid container xs={12} spacing={2} style={{ padding: "1em" }}>
                <Grid item container alignItems="center">
                  <Grid item xs={2}>
                    <Typography variant="subtitle2">Coverage</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    {printControls(controls).map((img, index) => (
                      <Link href={`/app/inventory/assets?coverage=${img[2]
                        }`} key={index} color="inherit">
                        <img
                          src={img[0]}
                          className={classes.coverageImg}
                          alt={img[1]}
                        />
                      </Link>
                    ))}
                  </Grid>
                </Grid>
                <Grid item container alignItems="center">
                  <Grid item xs={2}>
                    <Typography variant="subtitle2">Missing Coverage</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    {activeSolutions.filter((item) =>
                      !(controls.map((val) => val.solution_id).includes(item.id))
                    ).map((item, index) => (
                      <Link href={`/app/inventory/assets?missing_coverage=${item.id
                        }`} key={index} color="inherit">
                        <img
                          src={getLogoUrl(item)}
                          className={classes.coverageImg}
                          alt={item.display_name}
                        />
                      </Link>
                    ))}
                  </Grid>
                </Grid>
                <Divider style={{ width: "100%" }} />

                <Grid item container alignItems="center">
                  <Grid item xs={2}>
                    <Typography variant="subtitle2">Description</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="body2">{description}</Typography>
                  </Grid>
                </Grid>
                <Divider style={{ width: "100%" }} />

                <Grid item container xs={6} spacing={2}>
                  {assetData2
                    ?.sort((a, b) => (a.key > b.key ? 1 : -1))
                    ?.slice(0, Math.floor(assetData2?.length / 2))
                    ?.map((item) => (
                      <>
                        <Grid item container>
                          <Grid item xs={4}>
                            <Typography variant="subtitle2">
                              {item.key}
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography variant="body2">
                              {" "}
                              {["date_last_seen", "date_added"].includes(
                                item.id
                              ) ? (
                                <>
                                  <span>
                                    {`${moment(item.value)
                                      .local()
                                      .format("ddd DD MMM YYYY, hh:mm A")} `}
                                  </span>
                                  <span style={{ color: "gray" }}>
                                    ({item.value})
                                  </span>
                                </>
                              ) : (
                                item.value
                              )}
                            </Typography>
                          </Grid>
                          <Divider style={{ width: "100%" }} />
                        </Grid>
                      </>
                    ))}
                </Grid>
                <Grid item container xs={6} spacing={2}>
                  {assetData2
                    ?.sort((a, b) => (a.key > b.key ? 1 : -1))
                    ?.slice(
                      Math.floor(assetData2?.length / 2),
                      assetData2?.length
                    )
                    ?.map((item) => (
                      <>
                        <Grid item container>
                          <Grid item xs={4}>
                            <Typography variant="subtitle2">
                              {item.key}
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography variant="body2">
                              {" "}
                              {["date_last_seen", "date_added"].includes(
                                item.id
                              ) ? (
                                <>
                                  <span>
                                    {`${moment(item.value)
                                      .local()
                                      .format("ddd DD MMM YYYY, hh:mm A")} `}
                                  </span>
                                  <span style={{ color: "gray" }}>
                                    ({item.value})
                                  </span>
                                </>
                              ) : (
                                item.value
                              )}
                            </Typography>
                          </Grid>
                          <Divider style={{ width: "100%" }} />
                        </Grid>
                      </>
                    ))}
                </Grid>

                <Grid item container alignItems="center">
                  <Grid item xs={2}>
                    <Typography variant="subtitle2">
                      Software Packages
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Collapse
                      in={
                        softwarePackages?.length <= 20 ? true : showAllSoftware
                      }
                      collapsedSize={softwarePackages?.length <= 20 ? null : 65}
                    >
                      {softwarePackages.map((item, index) => (
                        <Chip
                          index={index}
                          component="a"
                          size="small"
                          target="_blank"
                          href={`/app/inventory/assets?software=${encodeURIComponent(
                            item
                          )}`}
                          clickable
                          variant="outlined"
                          label={item}
                          style={{ margin: ".25rem" }}
                        />
                      ))}
                    </Collapse>
                    {softwarePackages?.length > 20 && (
                      <Typography
                        variant="body2"
                        component="a"
                        style={{ color: palette.purple1 }}
                        onClick={() => setShowAllSoftware(!showAllSoftware)}
                      >
                        {showAllSoftware ? "Show Less" : "Show More"}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Divider style={{ width: "100%" }} />
                <Grid item container alignItems="center">
                  <Grid item xs={2}>
                    <Typography variant="subtitle2">Ports</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Collapse
                      in={ports?.length <= 20 ? true : showAllPorts}
                      collapsedSize={ports?.length <= 20 ? null : 65}
                    >
                      {ports.map((item, index) => (
                        <Chip
                          index={index}
                          component="a"
                          size="small"
                          target="_blank"
                          href={`/app/inventory/assets?port=${item?.split(" (")[0]
                            }`}
                          clickable
                          variant="outlined"
                          label={item}
                          style={{ margin: ".25rem" }}
                        />
                      ))}
                    </Collapse>
                    {ports?.length > 20 && (
                      <Typography
                        variant="body2"
                        component="a"
                        style={{ color: palette.purple1 }}
                        onClick={() => setShowAllPorts(!showAllPorts)}
                      >
                        {showAllPorts ? "Show Less" : "Show More"}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Divider style={{ width: "100%" }} />
                <Grid item container alignItems="center">
                  <Grid item xs={2}>
                    <Typography variant="subtitle2">Firewall Rules</Typography>
                  </Grid>
                  <Grid item xs={10}>
                  <Collapse
                      in={assetData?.firewall_rules?.length <= 20 ? true : showAllFirewall}
                      collapsedSize={assetData?.firewall_rules?.length <= 20 ? null : 65}
                    >
                      {assetData?.firewall_rules?.map((item, index) => (
                        <Chip
                          index={index}
                          component="a"
                          size="small"
                          target="_blank"
                          href={`/app/inventory/assets?firewall=${item?.split(" (")[0]
                            }`}
                          clickable
                          variant="outlined"
                          label={item}
                          style={{ margin: ".25rem" }}
                        />
                      ))}
                    </Collapse>
                    {assetData?.firewall_rules?.length > 20 && (
                      <Typography
                        variant="body2"
                        component="a"
                        style={{ color: palette.purple1 }}
                        onClick={() => setShowAllFirewall(!showAllFirewall)}
                      >
                        {showAllFirewall ? "Show Less" : "Show More"}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Divider style={{ width: "100%" }} />
                <Grid item container alignItems="center">
                  <Grid item xs={2}>
                    <Typography variant="subtitle2">Tags</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    {assetData?.tags?.map((tag, index) => {
                      return (
                        <Chip
                          index={index}
                          label={`${tag.display_name} - ${tag.value}`}
                          style={{ margin: ".25rem" }}
                        />
                      );
                    })}
                  </Grid>
                </Grid>
                <Divider style={{ width: "100%" }} />

              </Grid>
            </AccordionSection>
            <AccordionSection
              noFlex
              title={
                <Typography variant="h6" style={{ fontSize: "medium" }}>
                  Risk
                </Typography>
              }
              expanded={allExpanded ?? props.riskOpen ?? false}
            >
              <FactorsTree
                impactScore={Math.floor(
                  Number.parseFloat(lookupFieldValue("criticality"))
                ).toFixed(0)}
                isCritical={lookupFieldValue("is_critical")}
                riskData={riskData?.kpis}
                impactData={impactData?.kpis}
                riskScore={riskData?.risk_score}
              />
              {settings.data === true && (
                <Grid item xs={12}>
                  <Typography variant="subtitle2">
                    {" "}
                    Risk Probability Distribution
                  </Typography>
                  <div style={{ height: 300, width: "100%" }}>
                    <ProbabilityDistributionCurve
                      colors={[palette.purple1, "#CBCBCB"]}
                      data={[
                        {
                          name: "Organizational Risk",
                          x: riskData?.pdf?.x,
                          y: riskData?.pdf?.y,
                        },
                        // {
                        //   name: "Average Asset Risk",
                        //   x: riskData?.average_asset_risk?.x,
                        //   y: riskData?.average_asset_risk?.y,
                        // },
                      ]}
                    />
                  </div>
                </Grid>
              )}
            </AccordionSection>
            <AccordionSection
              title={
                <Typography variant="h6" style={{ fontSize: "medium" }}>
                  Vulnerabilities
                </Typography>
              }
              expanded={allExpanded ?? props.vulnsOpen ?? false}
            >
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <AssetCVETable assetId={assetId} />
                </Grid>
              </Grid>
            </AccordionSection>
            <AccordionSection
              title={
                <Typography variant="h6" style={{ fontSize: "medium" }}>
                  Alerts
                </Typography>
              }
              expanded={allExpanded ?? props.alertsOpen ?? false}
            >
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <AssetAlertTable assetId={assetId} />
                </Grid>
              </Grid>
            </AccordionSection>
            <AccordionSection
              title={
                <Typography variant="h6" style={{ fontSize: "medium" }}>
                  Logons
                </Typography>
              }
              expanded={allExpanded ?? props.logonsOpen ?? false}
            >
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <UserLogons data={userLogons} />
                </Grid>
              </Grid>
            </AccordionSection>
            <AccordionSection
              title={
                <Typography variant="h6" style={{ fontSize: "medium" }}>
                  Data Discovery
                </Typography>
              }
              expanded={allExpanded ?? props.dataDiscoveryOpen ?? false}
            >
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <DataDiscoveryResult assetId={assetId} />
                </Grid>
              </Grid>
            </AccordionSection>
          </div>
        </div>
      )}
      <StyledModal
        title="Delete Asset"
        destructive
        open={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
        }}
        onSubmit={(e) => {
          e.preventDefault();
          deleteAsset(assetId);
        }}
      >
        <Typography variant="subtitle1" style={{ textAlign: "center" }}>
          Are you sure you want to delete this asset?
        </Typography>
      </StyledModal>
    </>
  );
}

export default Drilldown;
